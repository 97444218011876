import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { appFonts, spacing, baseColors } from '../../../../styles';
import * as actions from '../../actions';
import { REGISTRATION_ITEM_IDS, EXTERNAL_SERVICES, DELAY_RENDER_TIME } from '../../constants';
import { getEnabledExternalServices, isFacebookEnabled, isGoogleEnabled, isSamlEnabled, isCheckingSamlStatus,
    isLoadingCompany, isOnlySamlEnabled, getCompany } from '../../selectors';
import { selectors as coreSelectors, translate, actions as coreActions, constants as coreConstants, tracker } from '../../../core';
import fb from '../../services/Facebook';
import google from '../../services/Google';
import { triggerSamlSSO } from '../../services/sso';

export default function WithSignUpSelectionBase(WrappedComponent) {
    class SignUpSelectionBase extends PureComponent {
        static propTypes = {
            actions: PropTypes.object.isRequired,
            company: PropTypes.object.isRequired,
            callback: PropTypes.func.isRequired,
            enabledExternalServices: PropTypes.array.isRequired,
            partnerSubdomain: PropTypes.string.isRequired,
            i18n: PropTypes.object.isRequired,
            step: PropTypes.string.isRequired,
            error: PropTypes.object,
            ssoDetails: PropTypes.object.isRequired,
            isCheckingSamlStatus: PropTypes.bool,
            isSamlEnabled: PropTypes.bool,
            isOnlySamlEnabled: PropTypes.bool,
            isLoadingCompany: PropTypes.bool
        };

        static defaultProps = {
            isCheckingSamlStatus: false,
            error: undefined,
            isSamlEnabled: false,
            isOnlySamlEnabled: false,
            isLoadingCompany: false
        };

        constructor(props) {
            super(props);
            this.state = { wait: true };
        }

        componentDidMount() {
            setTimeout(() => {
                this.setState({ wait: false });
            }, this.props.isOnlySamlEnabled ? DELAY_RENDER_TIME : 0);
        }

        componentDidUpdate(prevProps) {
            if (prevProps.isCheckingSamlStatus && !this.props.isCheckingSamlStatus) {
                // if (_.get(this.props.ssoDetails, 'status') === SAML_SSO_STATUSES.login) {
                //
                // } else if (_.get(this.props.ssoDetails, 'status') === SAML_SSO_STATUSES.registration) {
                //     this.props.callback(this.props.step, REGISTRATION_ITEM_IDS.register);
                // } else {
                //     this.props.callback(this.props.step, REGISTRATION_ITEM_IDS.samlError);
                // }
                this.props.callback(this.props.step, REGISTRATION_ITEM_IDS.saml);
            }
        }

        signUpFacebook = () => {
            fb.login(({ accessToken }) => {
                fb.me(response => {
                    tracker.logEvent('Registration_ViaFacebook');
                    this.props.actions.updateUserSSOInfo({
                        ...response,
                        token: accessToken,
                        ssotype: EXTERNAL_SERVICES.facebook
                    });
                    this.props.callback(this.props.step, REGISTRATION_ITEM_IDS.facebook);
                }, error => this.facebookGoogleAppleErrorHandler(EXTERNAL_SERVICES.facebook, error));
            }, error => this.facebookGoogleAppleErrorHandler(EXTERNAL_SERVICES.facebook, error));
        };

        signUpGoogle = () => {
            google.login(({ accessToken }) => {
                google.me(response => {
                    tracker.logEvent('Registration_ViaGoogle');
                    this.props.actions.updateUserSSOInfo({
                        ...response,
                        token: accessToken,
                        ssotype: EXTERNAL_SERVICES.google
                    });
                    this.props.callback(this.props.step, REGISTRATION_ITEM_IDS.google);
                });
            }, error => this.facebookGoogleAppleErrorHandler(EXTERNAL_SERVICES.google, error));
        };

        facebookGoogleAppleErrorHandler = (ssotype, error) => {
            if (error) {
                this.props.actions.addToast(coreConstants.TOAST_TYPES.DANGER, null,
                    error.displayMessage || error.message || this.props.i18n.t('registration.generalError'), ssotype);
            }
        };

        signUpEmail = () => {
            tracker.logEvent('Registration_ViaEmail');
            this.props.callback(this.props.step, REGISTRATION_ITEM_IDS.email);
        };

        signUpCorporate = () => {
            tracker.logEvent('sign_up', { method: 'corporate' });
            this.props.callback(this.props.step, REGISTRATION_ITEM_IDS.corporate);
        };

        signUpSaml = () => {
            tracker.logEvent('sign_up', { method: 'saml' });
            triggerSamlSSO(this.props.company);
        };

        clear = () => {
            this.props.actions.clearUserSSOInfo();
            this.props.actions.resetCompanyConfiguration();
        };

        // wait is needed to avoid flickering when the child launches the in app browser
        // the in app browser launches almost immediately but avoid rendering the screen before the in app browser appears
        // conversely, using callbacks and state etc is too complex
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    signUpCorporate={this.signUpCorporate}
                    signUpEmail={this.signUpEmail}
                    signUpGoogle={this.signUpGoogle}
                    signUpFacebook={this.signUpFacebook}
                    signUpSaml={this.signUpSaml}
                    clear={this.clear}
                    wait={this.state.wait}
                    isLoading={this.props.isCheckingSamlStatus || this.props.isLoadingCompany}
                />
            );
        }
    }

    function mapStateToProps(state) {
        return {
            enabledExternalServices: getEnabledExternalServices(state),
            partnerSubdomain: coreSelectors.getPartnerSubdomain(state),
            isFacebookEnabled: isFacebookEnabled(state),
            isGoogleEnabled: isGoogleEnabled(state),
            isSamlEnabled: isSamlEnabled(state),
            isOnlySamlEnabled: isOnlySamlEnabled(state),
            isCheckingSamlStatus: isCheckingSamlStatus(state),
            isLoadingCompany: isLoadingCompany(state),
            company: getCompany(state),
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators({ ...coreActions, ...actions }, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(SignUpSelectionBase));
}

export const styles = {
    buttonContainer: {
        marginBottom: spacing.s5
    },
    button: {
        marginTop: spacing.s0,
        marginBottom: spacing.s0
    },
    corporateTextArea: {
        marginLeft: spacing.s5,
        marginRight: spacing.s5,
        flexDirection: 'column',
        flex: 1,
        marginTop: spacing.s0,
        alignItems: 'center'
    },
    corporateText: {
        ...appFonts.mdRegular,
        textAlign: 'center'
    },
    buttonText: {
        ...appFonts.lgMedium
    },
    buttonFacebook: {
        borderWidth: 0,
        backgroundColor: baseColors.facebook
    },
    buttonGoogle: {
        borderWidth: 0,
        backgroundColor: baseColors.google
    },
    buttonTextWhite: { color: baseColors.white },
    buttonTextSecondary: { color: baseColors.secondary }
};
