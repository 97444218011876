import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { baseColors } from '../../../../styles';
import { Alert, translate, selectors as coreSelectors } from '../../../core';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import { triggerSSO } from '../../services/sso';
import { EXTERNAL_SERVICES, SSO_CONTEXT } from '../../constants';
import fb from '../../services/Facebook';
import google from '../../services/Google';

export const ICONS = {
    google: { name: 'google', color: baseColors.google, type: 'brands' },
    facebook: { name: 'facebook-square', color: baseColors.facebook, type: 'brands' },
    add: { name: 'plus', color: baseColors.primary, type: 'light' },
    check: { name: 'check', color: baseColors.primary, type: 'light' },
    user: { name: 'user-circle', color: 'black', type: 'light' },
    corporate: { name: 'briefcase', color: 'black', type: 'light' },
    remove: { name: 'trash-alt', color: 'danger', type: 'light' },
    apple: { name: 'apple', color: baseColors.apple, type: 'brands' }
};

export default function WithLinkAccountsBase(WrappedComponent) {
    class LinkAccountsBase extends PureComponent {
        static propTypes = {
            partnerSSO: PropTypes.object,
            partnerSubdomain: PropTypes.string.isRequired,
            actions: PropTypes.object.isRequired,
            company: PropTypes.object.isRequired,
            externalAccounts: PropTypes.array.isRequired,
            facebookEnabled: PropTypes.bool.isRequired,
            googleEnabled: PropTypes.bool.isRequired,
            programName: PropTypes.string.isRequired,
            noEnabledServices: PropTypes.bool.isRequired,
            i18n: PropTypes.object.isRequired,
            enabledExternalServices: PropTypes.array.isRequired,
            isLoading: PropTypes.bool,
            isLastAccount: PropTypes.bool.isRequired,
            email: PropTypes.string.isRequired,
            partnerId: PropTypes.string.isRequired,
            userId: PropTypes.string.isRequired,
            partnerSsoLinkingModal: PropTypes.object,
        };

        static defaultProps = {
            isLoading: false,
            partnerSSO: undefined,
            partnerSsoLinkingModal: undefined,
        };

        constructor(props) {
            super(props);
            this.state = { email: '' };
            const { partnerSubdomain: programName } = props;
            programName && props.actions.getProgramDetails({ programName });
        }

        get isGoogleLinked() {
            return _.includes(this.props.externalAccounts, EXTERNAL_SERVICES.google);
        }

        get isFacebookLinked() {
            return _.includes(this.props.externalAccounts, EXTERNAL_SERVICES.facebook);
        }

        get isCorporateAccountLinked() {
            return _.includes(this.props.externalAccounts, EXTERNAL_SERVICES.corporate);
        }

        isOtherLinked = account => _.includes(this.props.externalAccounts, account);

        linkOtherAccount = slug => () => {
            this.isOtherLinked(slug) ? this.alert() : this.triggerSSO();
        };

        unlinkAccount = handler => () => {
            const { isLastAccount, i18n, email } = this.props;

            if (!isLastAccount) return handler();

            Alert.alert('', i18n.t('auth.linkAccount.lastAccountMessage', { email }), [
                { text: i18n.t('reset_password'), onPress: handler, isPrimary: true },
                { text: i18n.t('button_cancel'), style: 'cancel' }
            ]);
        };

        triggerSSO = () => {
            this.props.partnerSSO && this.props.partnerSSO.uri
                ? triggerSSO({ target: this.props.partnerSSO.uri }, SSO_CONTEXT.accountLink, this.props.company)
                : this.alert(this.props.i18n.t('core.featureNotEnabled'));
        };

        unlinkOtherAccount = slug => () => {
            this.disconnectAccount({ ssotype: slug });
        };

        linkGoogle = () => {
            this.isGoogleLinked
                ? this.alert()
                : google.login(response => {
                    this.props.actions.ssoLinkUser({
                        token: response.accessToken,
                        ssotype: EXTERNAL_SERVICES.google
                    });
                });
        };

        unlinkGoogle = () => {
            this.disconnectAccount({ ssotype: EXTERNAL_SERVICES.google });
        };

        linkFacebook = () => {
            this.isFacebookLinked
                ? this.alert()
                : fb.login(response => {
                    this.props.actions.ssoLinkUser({
                        token: response.accessToken,
                        ssotype: EXTERNAL_SERVICES.facebook
                    });
                });
        };

        unlinkFacebook = () => {
            this.disconnectAccount({ ssotype: EXTERNAL_SERVICES.facebook });
        };

        linkCorporateAccount = () => {
            this.props.actions.updateCorporateAccountLinkData({ partner_id: this.props.partnerId, user_id: this.props.userId }, true);
        };

        unlinkCorporateAccount = () => {
            const { i18n, isLastAccount, email } = this.props;

            if (isLastAccount) return this.onUnlinkCorporateAccount();

            Alert.alert('', i18n.t('auth.linkAccount.unlinkCorpAccountMessage', { email }), [
                { text: i18n.t('ok'), onPress: this.onUnlinkCorporateAccount, isPrimary: true },
                { text: i18n.t('button_cancel'), style: 'cancel' },
            ]);
        };

        onUnlinkCorporateAccount = () => {
            this.props.actions.unlinkCorporateAccount({ ssotype: EXTERNAL_SERVICES.corporate }, this.props.isLastAccount);
        };

        disconnectAccount = params => {
            this.props.actions.disconnectExternalAccount(params, this.props.isLastAccount);
        };

        alert = (msg = this.props.i18n.t('auth.linkAccount.alreadyLinked')) => {
            Alert.alert('', msg);
        };

        get programName() {
            return this.props.programName || this.props.i18n.t('appname');
        }

        onEmailChange = email => {
            this.setState({ email });
        };

        getLinkInfoModalProps = (okHandler, cancelHandler) => {
            const { partnerSsoLinkingModal, i18n } = this.props;

            return {
                iconName: 'user',
                title: partnerSsoLinkingModal.title_string[i18n.acceptLanguage()],
                text: partnerSsoLinkingModal.first_string[i18n.acceptLanguage()],
                textSecondLine: partnerSsoLinkingModal.second_string[i18n.acceptLanguage()],
                isButtonVisible: false,
                buttons: [
                    { text: i18n.t('got_it'), onPress: () => okHandler(), isBoldText: true },
                    { text: i18n.t('button_cancel'), onPress: () => cancelHandler() }
                ],
            };
        };

        get title() {
            return this.props.i18n.t('linkAccounts');
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    programName={this.programName}
                    isGoogleLinked={this.isGoogleLinked}
                    isFacebookLinked={this.isFacebookLinked}
                    isOtherLinked={this.isOtherLinked}
                    linkOtherAccount={this.linkOtherAccount}
                    unlinkOtherAccount={this.unlinkOtherAccount}
                    linkGoogle={this.linkGoogle}
                    unlinkGoogle={this.unlinkGoogle}
                    linkFacebook={this.linkFacebook}
                    unlinkFacebook={this.unlinkFacebook}
                    unlinkAccount={this.unlinkAccount}
                    onEmailChange={this.onEmailChange}
                    email={this.state.email}
                    isCorporateAccountLinked={this.isCorporateAccountLinked}
                    linkCorporateAccount={this.linkCorporateAccount}
                    unlinkCorporateAccount={this.unlinkCorporateAccount}
                    getLinkInfoModalProps={this.getLinkInfoModalProps}
                    title={this.title}
                />
            );
        }
    }

    function mapStateToProps(state, ownProps) {
        return {
            email: coreSelectors.getCurrentUser(state).email,
            partnerSubdomain: coreSelectors.getPartnerSubdomain(state),
            enabledExternalServices: selectors.getOtherEnabledExternalServices(state),
            noEnabledServices: !selectors.isEnabledExternalServices(state),
            partnerSSO: selectors.getPartner(state).sso,
            externalAccounts: selectors.getEnabledExternalAccountNames(state),
            facebookEnabled: selectors.isFacebookEnabled(state),
            googleEnabled: selectors.isGoogleEnabled(state),
            programName: selectors.getProgramName(state),
            isLoading: selectors.isLinkingAccount(state) || selectors.isDeletingAccount(state) || selectors.isLoadingProgramDetails(state),
            isLastAccount: selectors.isLastEnabledExternalAccount(state),
            corporateAccountLinkEnabled: selectors.isRetailUser(state),
            partnerId: selectors.getPartnerId(state),
            userId: coreSelectors.getCurrentUserId(state),
            hasPartnerSsoLinkingModal: selectors.hasPartnerSsoLinkingModal(state),
            partnerSsoLinkingModal: selectors.getPartnerSsoLinkingModal(state),
            company: selectors.getCompany(state),
            appleEnabled: selectors.isAppleEnabled(state)
        };
    }

    function mapDispatchToProps(dispatch) {
        return {
            actions: bindActionCreators(actions, dispatch)
        };
    }

    return connect(mapStateToProps, mapDispatchToProps)(translate()(LinkAccountsBase));
}
