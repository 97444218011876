import moment from 'moment';
import configBuild, { BUILDS as configBuilds } from '../../../config/buildVariant';
import { baseColors, baseIconColors } from '../../../styles';

export { default as ROUTES } from './routes';

export const NAME = 'core';
export const NOT_PERSISTED = true;

export const BUILDS = Object.freeze({ ...configBuilds });

export const BUILD = configBuild;

export const IS_LIVE_BETTER = BUILD === BUILDS.mylivebetter;

export const APP_RATING_DELAY = 5000;

export const API_CALL_STATES = Object.freeze({
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
});

export const KEYBOARD_TYPES = Object.freeze({
    ascii: 'ascii-capable',
    default: 'default',
});

export const NAME_KEYBOARD_TYPE = IS_LIVE_BETTER ? KEYBOARD_TYPES.ascii : KEYBOARD_TYPES.default;

export const CHILD_NAMES = Object.freeze({
    toasts: 'toasts',
    loading: 'loading',
    error: 'error',
    general: 'general'
});

export const TOAST_LIVE_TIME = 5; // seconds
export const TOAST_LIVE_MS_TIME = TOAST_LIVE_TIME * 1000; //milliseconds
export const TOAST_ACCESIBILITY_MS_TIME = TOAST_LIVE_TIME * 2 * 1000; //milliseconds
export const NEXT_SNACK_TIMEOUT = 200; // milliseconds
export const MULTIPLE_INPUT_ROWS = 10;
export const SCROLL_DELAY = 100; // milliseconds
export const DELAY_100 = 100; // milliseconds
export const HEADER_TRANSITION_DURATION = 300; // milliseconds
export const SKELETON_ANIMATION_DELAY = 4000; // milliseconds
export const CHALLENGE_REFRESH_DELAY_FAST = 3500; // 3.5s
export const CHALLENGE_REFRESH_DELAY_SLOW = 13000; // 13s
export const UPGRADE_MODAL_REPEAT = 1000;
export const UPGRADE_MODAL_RESET = 5000;
export const UPLOAD_IMAGE_SIZE = 1280;
export const UPLOAD_IMAGE_QUALITY = 0.7;

export const TOAST_TYPES = Object.freeze({
    SUCCESS: 'success',
    DANGER: 'danger',
    INFO: 'info'
});

export const FILTER_TYPES = Object.freeze({
    TIME_PERIODS: 'time_periods',
    STREAM_ITEMS: 'stream_items',
    SHARE_WITH: 'share_with',
    LEADERBOARD: 'leaderboard',
    ACTIVITES: 'activities'
});

export const SHARE_WITH_REFERENCES = Object.freeze({
    onlyMe: 'me'
});

export const CHALLENGE_DURATION_REFERENCE = 'challenge-start';

export const MAX_SLIDES_COUNT = 10;

export const getIconCategories = (iconColors = baseIconColors) => Object.freeze({
    cardio: iconColors.move,
    strength: iconColors.move,
    pump: iconColors.move,
    play: iconColors.move,
    move: iconColors.move,
    feel_good: iconColors.mind,
    mental_health: iconColors.mind,
    mind: iconColors.mind,
    feel: iconColors.mind,
    nourish: iconColors.eat,
    eat: iconColors.eat,
    breathe: iconColors.breathe,
    multiple: iconColors.company,
    company: iconColors.company,
    department: iconColors.company,
    region: iconColors.company,
    program: iconColors.program,
    users: iconColors.users,
    leaf: iconColors.leaf,
    clipboard: iconColors.clipboard,
    goal: iconColors.goal,
    challenge: iconColors.challenge
});

export const ICON_CATEGORIES = getIconCategories();

export const DEFAULT_ICON_CATEGORY = ICON_CATEGORIES.leaf;

export const CSS_CLASSES = Object.freeze({
    customCarousel: 'custom-carousel',
    slickActive: 'slick-active',
    modalBody: 'modal-body',
    modalBodyWithScrollbar: 'modal-body-with-scrollbar',
    rewards: 'rewards',
    dashboardTourTarget: 'dashboardTourTarget',
    goals: 'goals',
    exploreMenuItem: 'exploreMenuItem',
    meMenuItem: 'meMenuItem',
    addedFriendsTarget: 'addedFriendsTarget',
    datesSwiper: 'dates-swiper',
    disclaimerText: 'disclaimer-text',
    explore: 'explore',
    reactCalendar: 'reactCalendar',
    communities: 'communities'
});

export const CAROUSEL_ITEM_TYPES = Object.freeze({
    CONTENT_LIBRARY: 'CONTENT_LIBRARY',
    COMMUNITIES: 'COMMUNITIES',
    EVENTS: 'EVENTS',
    CHALLENGES: 'CHALLENGES',
    PRODUCTS: 'PRODUCTS',
    RESOURCES: 'RESOURCES',
    INVITES: 'INVITES'
});

export const DATE_FORMATS = Object.freeze({
    full: 'YYYY-MM-DD',
    monthShort: 'MM',
    month: 'MMM',
    monthFull: 'MMMM',
    monthFullDayYearFull: 'MMMM DD, YYYY',
    monthFullDayStYearFull: 'MMMM, Do, YYYY',
    monthFullDayShortYearFull: 'MMMM D, YYYY',
    monthDayYearFull: 'MMM D, YYYY',
    monthDay: 'MMM D',
    monthFullDay: 'MMMM DD',
    monthFullDayShort: 'MMMM D',
    dayFull: 'ddd',
    dayFullString: 'dddd',
    day: 'DD',
    dayShort: 'D',
    dayFullMonthFullDay: 'ddd, MMMM DD',
    dayFullMonthDay: 'ddd, MMM D',
    twentyFourHoursTime: 'HH:mm:ss',
    twelveHoursTime: 'h:mm A',
    shortTime: 'h:mma',
    dayFullMonthPointFullDay: 'dddd, MMM. DD',
    monthYear: 'MMM YYYY',
    dayFullMonthFullYear: 'DD MMMM, YYYY',
    monthFullDayShortTime: 'MMMM DD @ h:mma • ',
    monthFullDayTwelveHoursTime: 'MMMM DD @ ha • ',
    fullSlash: 'YYYY/MM/DD',
    fullDayShortMontNum: 'dddd, MMM DD',
    fullDayMonthFullDay: 'dddd, MMMM D'
});

export const NOT_IMPLEMENTED_MESSAGE = 'not implemented yet';

export const USER_ROLES = Object.freeze({
    USER: 'user',
    ADMIN: 'admin',
    PARTNER_ADMIN: 'partner-admin',
    MODERATOR: 'moderator',
    DEVELOPER: 'developer'
});

export const PARTICIPANTS_NUMBER_AVATARS = 5;

export const MEMBERS_MAX_COUNT = 10;

export const MAX_COUNT = 10;

export const MAX_COUNT_MORE = 25;

export const PLATFORMS = Object.freeze({
    android: 'android',
    ios: 'ios',
    web: 'web',
});

export const PLATFORMS_GENERAL = Object.freeze({
    mobile: 'mobile',
    web: 'web',
});

export const HEALTH_KIT = 'healthKit';

export const SEARCH_INPUT_DELAY = 500;

export const ALERT_TIMEOUT = 500;

export const ALERT_TIMEOUT_LONG = 3000;

export const ENTITIES = Object.freeze({
    challenge: 'challenge',
    goal: 'goal',
    community: 'community',
    team: 'team',
    event: 'event',
    group: 'group'
});

export const DEFAULT_DATE = moment().subtract(25, 'years').toDate();

export const CAROUSEL_TYPES = {
    single: 'single',
    multiple: 'multiple',
    multipleForLibrary: 'multipleForLibrary',
    twoOnly: 'twoOnly',
    threeOnly: 'threeOnly',
    singleCenter: 'singleCenter',
    goalsCarousel: 'goalsCarousel'
};

export const LIST_OPTION_TYPES = Object.freeze({
    ACTION_BUTTON: 0,
    EXTERNAL_LINK_BUTTON: 1,
    ACTION_SHEET_BUTTON: 2,
    SWITCH_BUTTON: 3,
    ACTION_BUTTON_WITH_ITEM: 4
});

export const DEEP_URLS = Object.freeze({
    password_reset: 'password_reset',
    consent_given: 'consent_given',
    oauth: 'oauth',
    confirm_user: 'auth/confirm_user',
    link_corporate_account: 'auth/link_corporate_account',
    integrate: 'integrate/index',
    appsDevicesSuccess: 'apps-devices?status=',
    unsubscribeEmails: 'emails/unsubscribe',
});

export const CREATE_ENTITY_MAX_NAME_LEN = 200;

export const ENTITIES_ACTIVE_TAB = Object.freeze({
    APPS_DEVICES: 'AppsDevicesActiveTab',
    ACTIVITY_LOG: 'ActivityLogActiveTab',
    PERSONAL_GOALS: 'PersonalGoalsActiveTab',
    CONTENT: 'ContentActiveTab',
    EXPLORE_COMMUNITIES: 'ExploreCommunitiesActiveTab',
    EXPLORE_CHALLENGES: 'ExploreChallengesActiveTab',
    EXPLORE_EVENTS: 'ExploreEventsActiveTab',
    EARN_PARTNERS: 'EarnPartnersActiveTab',
    MY_ACHIEVEMENTS: 'MyAchievementsActivetab',
    REWARDS: 'RewardsActivetab',
    REWARDS_DETAILS: 'RewardsDetailsActivetab',
    PROFILE_EDITOR: 'ProfileEditorActivetab',
    SETTINGS: 'SettingsActiveTab',
    ME: 'MeActiveTab',
    SCORE: 'ScoreActiveTab'
});

export const BRAND_COLOR = Object.freeze({
    LIGHT_SHADE: 92,
    DARK_SHADE: 16,
    SATURATION: 100
});

export const SEARCH_MAX_LENGTH = 100;

export const LANGUAGES = {
    en: 'en',
    fr: 'fr',
    es: 'es',
};

export const CM_TO_IN_DENOMINATOR = 0.394;
export const KG_TO_LBS_DENOMINATOR = 2.2;

export const getChevronRight = (color = baseColors.grey50) => ({ name: 'chevron-right', type: 'font-awesome', color });

export const WARNING_POINTS_NUMBER = 1200;

export const DEFAULT_NAVBAR_BORDER = { headerStyle: { shadowColor: undefined } };

export const USE_V2_API = true;

export const DATE_FIELD_TYPE = Object.freeze({
    text: 'TextField',
    picker: 'DatePicker'
});
